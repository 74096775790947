export const SDDemo: any = {
  last_node_id: 9,
  last_link_id: 9,
  nodes: [
    {
      id: 7,
      type: 'CLIPTextEncode',
      pos: [413, 389],
      size: {
        '0': 425.27801513671875,
        '1': 180.6060791015625
      },
      flags: {},
      order: 3,
      mode: 0,
      inputs: [
        {
          name: 'clip',
          type: 'CLIP',
          link: 5
        }
      ],
      outputs: [
        {
          name: 'CONDITIONING',
          type: 'CONDITIONING',
          links: [6],
          slot_index: 0
        }
      ],
      properties: {
        'Node name for S&R': 'CLIPTextEncode'
      },
      widgets_values: ['text, watermark']
    },
    {
      id: 6,
      type: 'CLIPTextEncode',
      pos: [415, 186],
      size: {
        '0': 422.84503173828125,
        '1': 164.31304931640625
      },
      flags: {},
      order: 2,
      mode: 0,
      inputs: [
        {
          name: 'clip',
          type: 'CLIP',
          link: 3
        }
      ],
      outputs: [
        {
          name: 'CONDITIONING',
          type: 'CONDITIONING',
          links: [4],
          slot_index: 0
        }
      ],
      properties: {
        'Node name for S&R': 'CLIPTextEncode'
      },
      widgets_values: [
        'beautiful scenery nature glass bottle landscape, , purple galaxy bottle,'
      ]
    },
    {
      id: 5,
      type: 'EmptyLatentImage',
      pos: [473, 609],
      size: {
        '0': 315,
        '1': 106
      },
      flags: {},
      order: 0,
      mode: 0,
      outputs: [
        {
          name: 'LATENT',
          type: 'LATENT',
          links: [2],
          slot_index: 0
        }
      ],
      properties: {
        'Node name for S&R': 'EmptyLatentImage'
      },
      widgets_values: [512, 512, 1]
    },
    {
      id: 3,
      type: 'KSampler',
      pos: [863, 186],
      size: {
        '0': 315,
        '1': 262
      },
      flags: {},
      order: 4,
      mode: 0,
      inputs: [
        {
          name: 'model',
          type: 'MODEL',
          link: 1
        },
        {
          name: 'positive',
          type: 'CONDITIONING',
          link: 4
        },
        {
          name: 'negative',
          type: 'CONDITIONING',
          link: 6
        },
        {
          name: 'latent_image',
          type: 'LATENT',
          link: 2
        }
      ],
      outputs: [
        {
          name: 'LATENT',
          type: 'LATENT',
          links: [7],
          slot_index: 0
        }
      ],
      properties: {
        'Node name for S&R': 'KSampler'
      },
      widgets_values: [
        156680208700286,
        'randomize',
        20,
        8,
        'euler',
        'normal',
        1
      ]
    },
    {
      id: 8,
      type: 'VAEDecode',
      pos: [1209, 188],
      size: {
        '0': 210,
        '1': 46
      },
      flags: {},
      order: 5,
      mode: 0,
      inputs: [
        {
          name: 'samples',
          type: 'LATENT',
          link: 7
        },
        {
          name: 'vae',
          type: 'VAE',
          link: 8
        }
      ],
      outputs: [
        {
          name: 'IMAGE',
          type: 'IMAGE',
          links: [9],
          slot_index: 0
        }
      ],
      properties: {
        'Node name for S&R': 'VAEDecode'
      }
    },
    {
      id: 9,
      type: 'SaveImage',
      pos: [1451, 189],
      size: {
        '0': 210,
        '1': 58
      },
      flags: {},
      order: 6,
      mode: 0,
      inputs: [
        {
          name: 'images',
          type: 'IMAGE',
          link: 9
        }
      ],
      properties: {},
      widgets_values: ['ComfyUI']
    },
    {
      id: 4,
      type: 'CheckpointLoaderSimple',
      pos: [26, 474],
      size: {
        '0': 315,
        '1': 98
      },
      flags: {},
      order: 1,
      mode: 0,
      outputs: [
        {
          name: 'MODEL',
          type: 'MODEL',
          links: [1],
          slot_index: 0
        },
        {
          name: 'CLIP',
          type: 'CLIP',
          links: [3, 5],
          slot_index: 1
        },
        {
          name: 'VAE',
          type: 'VAE',
          links: [8],
          slot_index: 2
        }
      ],
      properties: {
        'Node name for S&R': 'CheckpointLoaderSimple'
      },
      widgets_values: ['Realistic-Vision-V6.0-B1.safetensors']
    }
  ],
  links: [
    [1, 4, 0, 3, 0, 'MODEL'],
    [2, 5, 0, 3, 3, 'LATENT'],
    [3, 4, 1, 6, 0, 'CLIP'],
    [4, 6, 0, 3, 1, 'CONDITIONING'],
    [5, 4, 1, 7, 0, 'CLIP'],
    [6, 7, 0, 3, 2, 'CONDITIONING'],
    [7, 3, 0, 8, 0, 'LATENT'],
    [8, 4, 2, 8, 1, 'VAE'],
    [9, 8, 0, 9, 0, 'IMAGE']
  ],
  groups: [],
  config: {},
  extra: {
    ds: {
      scale: 1,
      offset: [58.85699462890625, -132.57135009765625]
    }
  },
  version: 0.4
}

export const ControlNetDemo: any = {
  last_node_id: 15,
  last_link_id: 23,
  nodes: [
    {
      id: 5,
      type: 'EmptyLatentImage',
      pos: [473, 609],
      size: {
        '0': 315,
        '1': 106
      },
      flags: {},
      order: 0,
      mode: 0,
      outputs: [
        {
          name: 'LATENT',
          type: 'LATENT',
          links: [2],
          slot_index: 0
        }
      ],
      properties: {
        'Node name for S&R': 'EmptyLatentImage'
      },
      widgets_values: [512, 512, 1]
    },
    {
      id: 3,
      type: 'KSampler',
      pos: [863, 186],
      size: {
        '0': 315,
        '1': 262
      },
      flags: {},
      order: 8,
      mode: 0,
      inputs: [
        {
          name: 'model',
          type: 'MODEL',
          link: 1
        },
        {
          name: 'positive',
          type: 'CONDITIONING',
          link: 18
        },
        {
          name: 'negative',
          type: 'CONDITIONING',
          link: 19
        },
        {
          name: 'latent_image',
          type: 'LATENT',
          link: 2
        }
      ],
      outputs: [
        {
          name: 'LATENT',
          type: 'LATENT',
          links: [7],
          slot_index: 0
        }
      ],
      properties: {
        'Node name for S&R': 'KSampler'
      },
      widgets_values: [
        983011523987039,
        'randomize',
        20,
        8,
        'euler',
        'normal',
        1
      ]
    },
    {
      id: 8,
      type: 'VAEDecode',
      pos: [1209, 188],
      size: {
        '0': 210,
        '1': 46
      },
      flags: {},
      order: 9,
      mode: 0,
      inputs: [
        {
          name: 'samples',
          type: 'LATENT',
          link: 7
        },
        {
          name: 'vae',
          type: 'VAE',
          link: 8
        }
      ],
      outputs: [
        {
          name: 'IMAGE',
          type: 'IMAGE',
          links: [9],
          slot_index: 0
        }
      ],
      properties: {
        'Node name for S&R': 'VAEDecode'
      }
    },
    {
      id: 9,
      type: 'SaveImage',
      pos: [1451, 189],
      size: [210, 270],
      flags: {},
      order: 10,
      mode: 0,
      inputs: [
        {
          name: 'images',
          type: 'IMAGE',
          link: 9
        }
      ],
      properties: {},
      widgets_values: ['ComfyUI']
    },
    {
      id: 4,
      type: 'CheckpointLoaderSimple',
      pos: [26, 474],
      size: {
        '0': 315,
        '1': 98
      },
      flags: {},
      order: 1,
      mode: 0,
      outputs: [
        {
          name: 'MODEL',
          type: 'MODEL',
          links: [1],
          slot_index: 0
        },
        {
          name: 'CLIP',
          type: 'CLIP',
          links: [3, 5],
          slot_index: 1
        },
        {
          name: 'VAE',
          type: 'VAE',
          links: [8],
          slot_index: 2
        }
      ],
      properties: {
        'Node name for S&R': 'CheckpointLoaderSimple'
      },
      widgets_values: ['Realistic-Vision-V6.0-B1.safetensors']
    },
    {
      id: 7,
      type: 'CLIPTextEncode',
      pos: [413, 389],
      size: {
        '0': 425.27801513671875,
        '1': 180.6060791015625
      },
      flags: {},
      order: 5,
      mode: 0,
      inputs: [
        {
          name: 'clip',
          type: 'CLIP',
          link: 5
        }
      ],
      outputs: [
        {
          name: 'CONDITIONING',
          type: 'CONDITIONING',
          links: [17],
          slot_index: 0
        }
      ],
      properties: {
        'Node name for S&R': 'CLIPTextEncode'
      },
      widgets_values: ['text, watermark']
    },
    {
      id: 13,
      type: 'ACN_AdvancedControlNetApply',
      pos: [727, -188],
      size: {
        '0': 285.6000061035156,
        '1': 286
      },
      flags: {},
      order: 7,
      mode: 0,
      inputs: [
        {
          name: 'positive',
          type: 'CONDITIONING',
          link: 16
        },
        {
          name: 'negative',
          type: 'CONDITIONING',
          link: 17
        },
        {
          name: 'control_net',
          type: 'CONTROL_NET',
          link: 20
        },
        {
          name: 'image',
          type: 'IMAGE',
          link: 23
        },
        {
          name: 'mask_optional',
          type: 'MASK',
          link: null
        },
        {
          name: 'timestep_kf',
          type: 'TIMESTEP_KEYFRAME',
          link: null
        },
        {
          name: 'latent_kf_override',
          type: 'LATENT_KEYFRAME',
          link: null
        },
        {
          name: 'weights_override',
          type: 'CONTROL_NET_WEIGHTS',
          link: null
        },
        {
          name: 'model_optional',
          type: 'MODEL',
          link: null
        },
        {
          name: 'vae_optional',
          type: 'VAE',
          link: null
        }
      ],
      outputs: [
        {
          name: 'positive',
          type: 'CONDITIONING',
          links: [18],
          shape: 3,
          slot_index: 0
        },
        {
          name: 'negative',
          type: 'CONDITIONING',
          links: [19],
          shape: 3,
          slot_index: 1
        },
        {
          name: 'model_opt',
          type: 'MODEL',
          links: null,
          shape: 3
        }
      ],
      properties: {
        'Node name for S&R': 'ACN_AdvancedControlNetApply'
      },
      widgets_values: [1, 0, 1, '']
    },
    {
      id: 12,
      type: 'ControlNetLoaderAdvanced',
      pos: [348, -181],
      size: {
        '0': 315,
        '1': 58
      },
      flags: {},
      order: 2,
      mode: 0,
      inputs: [
        {
          name: 'tk_optional',
          type: 'TIMESTEP_KEYFRAME',
          link: null
        }
      ],
      outputs: [
        {
          name: 'CONTROL_NET',
          type: 'CONTROL_NET',
          links: [20],
          shape: 3,
          slot_index: 0
        }
      ],
      properties: {
        'Node name for S&R': 'ControlNetLoaderAdvanced'
      },
      widgets_values: ['control_v11p_sd15_canny.pth']
    },
    {
      id: 14,
      type: 'LoadImage',
      pos: [8, -73],
      size: [315, 314],
      flags: {},
      order: 3,
      mode: 0,
      outputs: [
        {
          name: 'IMAGE',
          type: 'IMAGE',
          links: [22],
          shape: 3,
          slot_index: 0
        },
        {
          name: 'MASK',
          type: 'MASK',
          links: null,
          shape: 3
        }
      ],
      properties: {
        'Node name for S&R': 'LoadImage'
      },
      widgets_values: ['e3b5cedf-4c88-4b9c-b73c-e9e7feb39871.png', 'image']
    },
    {
      id: 15,
      type: 'Canny',
      pos: [351, -73],
      size: {
        '0': 315,
        '1': 82
      },
      flags: {},
      order: 6,
      mode: 0,
      inputs: [
        {
          name: 'image',
          type: 'IMAGE',
          link: 22
        }
      ],
      outputs: [
        {
          name: 'IMAGE',
          type: 'IMAGE',
          links: [23],
          shape: 3,
          slot_index: 0
        }
      ],
      properties: {
        'Node name for S&R': 'Canny'
      },
      widgets_values: [0.4, 0.8]
    },
    {
      id: 6,
      type: 'CLIPTextEncode',
      pos: [415, 186],
      size: {
        '0': 422.84503173828125,
        '1': 164.31304931640625
      },
      flags: {},
      order: 4,
      mode: 0,
      inputs: [
        {
          name: 'clip',
          type: 'CLIP',
          link: 3
        }
      ],
      outputs: [
        {
          name: 'CONDITIONING',
          type: 'CONDITIONING',
          links: [16],
          slot_index: 0
        }
      ],
      properties: {
        'Node name for S&R': 'CLIPTextEncode'
      },
      widgets_values: ['a beautiful woman in a Japanese garden']
    }
  ],
  links: [
    [1, 4, 0, 3, 0, 'MODEL'],
    [2, 5, 0, 3, 3, 'LATENT'],
    [3, 4, 1, 6, 0, 'CLIP'],
    [5, 4, 1, 7, 0, 'CLIP'],
    [7, 3, 0, 8, 0, 'LATENT'],
    [8, 4, 2, 8, 1, 'VAE'],
    [9, 8, 0, 9, 0, 'IMAGE'],
    [16, 6, 0, 13, 0, 'CONDITIONING'],
    [17, 7, 0, 13, 1, 'CONDITIONING'],
    [18, 13, 0, 3, 1, 'CONDITIONING'],
    [19, 13, 1, 3, 2, 'CONDITIONING'],
    [20, 12, 0, 13, 2, 'CONTROL_NET'],
    [22, 14, 0, 15, 0, 'IMAGE'],
    [23, 15, 0, 13, 3, 'IMAGE']
  ],
  groups: [],
  config: {},
  extra: {
    ds: {
      scale: 0.6830134553650707,
      offset: [313.7001686977537, 240.37174085064697]
    }
  },
  version: 0.4
}

export const FluxDemo: any = {
  last_node_id: 40,
  last_link_id: 69,
  nodes: [
    {
      id: 8,
      type: 'VAEDecode',
      pos: [1151, 195],
      size: {
        '0': 210,
        '1': 46
      },
      flags: {},
      order: 5,
      mode: 0,
      inputs: [
        {
          name: 'samples',
          type: 'LATENT',
          link: 52,
          label: 'samples'
        },
        {
          name: 'vae',
          type: 'VAE',
          link: 46,
          label: 'vae'
        }
      ],
      outputs: [
        {
          name: 'IMAGE',
          type: 'IMAGE',
          links: [9],
          slot_index: 0,
          label: 'IMAGE'
        }
      ],
      properties: {
        'Node name for S&R': 'VAEDecode'
      }
    },
    {
      id: 31,
      type: 'KSampler',
      pos: [816, 192],
      size: {
        '0': 315,
        '1': 262
      },
      flags: {},
      order: 4,
      mode: 0,
      inputs: [
        {
          name: 'model',
          type: 'MODEL',
          link: 64,
          label: 'model'
        },
        {
          name: 'positive',
          type: 'CONDITIONING',
          link: 58,
          label: 'positive'
        },
        {
          name: 'negative',
          type: 'CONDITIONING',
          link: 68,
          label: 'negative'
        },
        {
          name: 'latent_image',
          type: 'LATENT',
          link: 69,
          label: 'latent_image'
        }
      ],
      outputs: [
        {
          name: 'LATENT',
          type: 'LATENT',
          links: [52],
          slot_index: 0,
          shape: 3,
          label: 'LATENT'
        }
      ],
      properties: {
        'Node name for S&R': 'KSampler'
      },
      widgets_values: [992437072724997, 'randomize', 4, 1, 'euler', 'simple', 1]
    },
    {
      id: 6,
      type: 'CLIPTextEncode',
      pos: [129, -14],
      size: {
        '0': 422.84503173828125,
        '1': 164.31304931640625
      },
      flags: {},
      order: 2,
      mode: 0,
      inputs: [
        {
          name: 'clip',
          type: 'CLIP',
          link: 66,
          label: 'clip'
        }
      ],
      outputs: [
        {
          name: 'CONDITIONING',
          type: 'CONDITIONING',
          links: [58],
          slot_index: 0,
          label: 'CONDITIONING'
        }
      ],
      title: 'CLIP Text Encode (Positive Prompt)',
      properties: {
        'Node name for S&R': 'CLIPTextEncode'
      },
      widgets_values: [
        'A beautiful woman wear white t-shirt and shorts with scattered flower pattern in a luxury bedroom'
      ],
      color: '#232',
      bgcolor: '#353'
    },
    {
      id: 39,
      type: 'CLIPTextEncode',
      pos: [131, 197],
      size: {
        '0': 422.84503173828125,
        '1': 164.31304931640625
      },
      flags: {},
      order: 3,
      mode: 0,
      inputs: [
        {
          name: 'clip',
          type: 'CLIP',
          link: 67,
          label: 'clip'
        }
      ],
      outputs: [
        {
          name: 'CONDITIONING',
          type: 'CONDITIONING',
          links: [68],
          slot_index: 0,
          label: 'CONDITIONING'
        }
      ],
      title: 'CLIP Text Encode (Negative Prompt)',
      properties: {
        'Node name for S&R': 'CLIPTextEncode'
      },
      widgets_values: ['Blur'],
      color: '#232',
      bgcolor: '#353'
    },
    {
      id: 9,
      type: 'SaveImage',
      pos: [1375, 194],
      size: {
        '0': 460.5380554199219,
        '1': 358.16357421875
      },
      flags: {},
      order: 6,
      mode: 0,
      inputs: [
        {
          name: 'images',
          type: 'IMAGE',
          link: 9,
          label: 'images'
        }
      ],
      properties: {},
      widgets_values: ['ComfyUI']
    },
    {
      id: 30,
      type: 'CheckpointLoaderSimple',
      pos: [-313, 190],
      size: {
        '0': 315,
        '1': 98
      },
      flags: {},
      order: 0,
      mode: 0,
      outputs: [
        {
          name: 'MODEL',
          type: 'MODEL',
          links: [64],
          slot_index: 0,
          shape: 3,
          label: 'MODEL'
        },
        {
          name: 'CLIP',
          type: 'CLIP',
          links: [66, 67],
          slot_index: 1,
          shape: 3,
          label: 'CLIP'
        },
        {
          name: 'VAE',
          type: 'VAE',
          links: [46],
          slot_index: 2,
          shape: 3,
          label: 'VAE'
        }
      ],
      properties: {
        'Node name for S&R': 'CheckpointLoaderSimple'
      },
      widgets_values: ['Flux1-Schnell.safetensors']
    },
    {
      id: 40,
      type: 'EmptySD3LatentImage',
      pos: [449, 450],
      size: {
        '0': 315,
        '1': 106
      },
      flags: {},
      order: 1,
      mode: 0,
      outputs: [
        {
          name: 'LATENT',
          type: 'LATENT',
          links: [69],
          shape: 3,
          slot_index: 0
        }
      ],
      properties: {
        'Node name for S&R': 'EmptySD3LatentImage'
      },
      widgets_values: [1024, 1024, 1]
    }
  ],
  links: [
    [9, 8, 0, 9, 0, 'IMAGE'],
    [46, 30, 2, 8, 1, 'VAE'],
    [52, 31, 0, 8, 0, 'LATENT'],
    [58, 6, 0, 31, 1, 'CONDITIONING'],
    [64, 30, 0, 31, 0, 'MODEL'],
    [66, 30, 1, 6, 0, 'CLIP'],
    [67, 30, 1, 39, 0, 'CLIP'],
    [68, 39, 0, 31, 2, 'CONDITIONING'],
    [69, 40, 0, 31, 3, 'LATENT']
  ],
  groups: [],
  config: {},
  extra: {
    ds: {
      scale: 0.5131581182307069,
      offset: [604.0780351814141, 216.96934724358178]
    }
  },
  version: 0.4
}

export const FluxDevDemo: any = {
  last_node_id: 37,
  last_link_id: 116,
  nodes: [
    {
      id: 17,
      type: 'BasicScheduler',
      pos: [480, 1008],
      size: {
        '0': 315,
        '1': 106
      },
      flags: {},
      order: 13,
      mode: 0,
      inputs: [
        {
          name: 'model',
          type: 'MODEL',
          link: 55,
          slot_index: 0
        }
      ],
      outputs: [
        {
          name: 'SIGMAS',
          type: 'SIGMAS',
          links: [20],
          shape: 3
        }
      ],
      properties: {
        'Node name for S&R': 'BasicScheduler'
      },
      widgets_values: ['simple', 20, 1]
    },
    {
      id: 16,
      type: 'KSamplerSelect',
      pos: [480, 912],
      size: {
        '0': 315,
        '1': 58
      },
      flags: {},
      order: 0,
      mode: 0,
      outputs: [
        {
          name: 'SAMPLER',
          type: 'SAMPLER',
          links: [19],
          shape: 3
        }
      ],
      properties: {
        'Node name for S&R': 'KSamplerSelect'
      },
      widgets_values: ['euler']
    },
    {
      id: 26,
      type: 'FluxGuidance',
      pos: [480, 144],
      size: {
        '0': 317.4000244140625,
        '1': 58
      },
      flags: {},
      order: 12,
      mode: 0,
      inputs: [
        {
          name: 'conditioning',
          type: 'CONDITIONING',
          link: 41
        }
      ],
      outputs: [
        {
          name: 'CONDITIONING',
          type: 'CONDITIONING',
          links: [42],
          shape: 3,
          slot_index: 0
        }
      ],
      properties: {
        'Node name for S&R': 'FluxGuidance'
      },
      widgets_values: [3.5],
      color: '#233',
      bgcolor: '#355'
    },
    {
      id: 22,
      type: 'BasicGuider',
      pos: [576, 48],
      size: {
        '0': 222.3482666015625,
        '1': 46
      },
      flags: {},
      order: 14,
      mode: 0,
      inputs: [
        {
          name: 'model',
          type: 'MODEL',
          link: 54,
          slot_index: 0
        },
        {
          name: 'conditioning',
          type: 'CONDITIONING',
          link: 42,
          slot_index: 1
        }
      ],
      outputs: [
        {
          name: 'GUIDER',
          type: 'GUIDER',
          links: [30],
          shape: 3,
          slot_index: 0
        }
      ],
      properties: {
        'Node name for S&R': 'BasicGuider'
      }
    },
    {
      id: 13,
      type: 'SamplerCustomAdvanced',
      pos: [864, 192],
      size: {
        '0': 272.3617858886719,
        '1': 124.53733825683594
      },
      flags: {},
      order: 15,
      mode: 0,
      inputs: [
        {
          name: 'noise',
          type: 'NOISE',
          link: 37,
          slot_index: 0
        },
        {
          name: 'guider',
          type: 'GUIDER',
          link: 30,
          slot_index: 1
        },
        {
          name: 'sampler',
          type: 'SAMPLER',
          link: 19,
          slot_index: 2
        },
        {
          name: 'sigmas',
          type: 'SIGMAS',
          link: 20,
          slot_index: 3
        },
        {
          name: 'latent_image',
          type: 'LATENT',
          link: 116,
          slot_index: 4
        }
      ],
      outputs: [
        {
          name: 'output',
          type: 'LATENT',
          links: [24],
          shape: 3,
          slot_index: 0
        },
        {
          name: 'denoised_output',
          type: 'LATENT',
          links: null,
          shape: 3
        }
      ],
      properties: {
        'Node name for S&R': 'SamplerCustomAdvanced'
      }
    },
    {
      id: 25,
      type: 'RandomNoise',
      pos: [480, 768],
      size: {
        '0': 315,
        '1': 82
      },
      flags: {},
      order: 1,
      mode: 0,
      outputs: [
        {
          name: 'NOISE',
          type: 'NOISE',
          links: [37],
          shape: 3
        }
      ],
      properties: {
        'Node name for S&R': 'RandomNoise'
      },
      widgets_values: [713223669522588, 'randomize'],
      color: '#2a363b',
      bgcolor: '#3f5159'
    },
    {
      id: 8,
      type: 'VAEDecode',
      pos: [866, 367],
      size: {
        '0': 210,
        '1': 46
      },
      flags: {},
      order: 16,
      mode: 0,
      inputs: [
        {
          name: 'samples',
          type: 'LATENT',
          link: 24
        },
        {
          name: 'vae',
          type: 'VAE',
          link: 12
        }
      ],
      outputs: [
        {
          name: 'IMAGE',
          type: 'IMAGE',
          links: [9],
          slot_index: 0
        }
      ],
      properties: {
        'Node name for S&R': 'VAEDecode'
      }
    },
    {
      id: 6,
      type: 'CLIPTextEncode',
      pos: [384, 240],
      size: {
        '0': 422.84503173828125,
        '1': 164.31304931640625
      },
      flags: {},
      order: 10,
      mode: 0,
      inputs: [
        {
          name: 'clip',
          type: 'CLIP',
          link: 10
        }
      ],
      outputs: [
        {
          name: 'CONDITIONING',
          type: 'CONDITIONING',
          links: [41],
          slot_index: 0
        }
      ],
      title: 'CLIP Text Encode (Positive Prompt)',
      properties: {
        'Node name for S&R': 'CLIPTextEncode'
      },
      widgets_values: [
        'cute anime girl with massive fluffy fennec ears and a big fluffy tail blonde messy long hair blue eyes wearing a maid outfit with a long black gold leaf pattern dress and a white apron mouth open holding a fancy black forest cake with candles on top in the kitchen of an old dark Victorian mansion lit by candlelight with a bright window to the foggy forest and very expensive stuff everywhere'
      ],
      color: '#232',
      bgcolor: '#353'
    },
    {
      id: 30,
      type: 'ModelSamplingFlux',
      pos: [480, 1152],
      size: {
        '0': 315,
        '1': 130
      },
      flags: {},
      order: 11,
      mode: 0,
      inputs: [
        {
          name: 'model',
          type: 'MODEL',
          link: 56,
          slot_index: 0
        },
        {
          name: 'width',
          type: 'INT',
          link: 115,
          widget: {
            name: 'width'
          },
          slot_index: 1
        },
        {
          name: 'height',
          type: 'INT',
          link: 114,
          widget: {
            name: 'height'
          },
          slot_index: 2
        }
      ],
      outputs: [
        {
          name: 'MODEL',
          type: 'MODEL',
          links: [54, 55],
          shape: 3,
          slot_index: 0
        }
      ],
      properties: {
        'Node name for S&R': 'ModelSamplingFlux'
      },
      widgets_values: [1.15, 0.5, 1024, 1024]
    },
    {
      id: 27,
      type: 'EmptySD3LatentImage',
      pos: [480, 624],
      size: {
        '0': 315,
        '1': 106
      },
      flags: {},
      order: 9,
      mode: 0,
      inputs: [
        {
          name: 'width',
          type: 'INT',
          link: 112,
          widget: {
            name: 'width'
          }
        },
        {
          name: 'height',
          type: 'INT',
          link: 113,
          widget: {
            name: 'height'
          }
        }
      ],
      outputs: [
        {
          name: 'LATENT',
          type: 'LATENT',
          links: [116],
          shape: 3,
          slot_index: 0
        }
      ],
      properties: {
        'Node name for S&R': 'EmptySD3LatentImage'
      },
      widgets_values: [1024, 1024, 1]
    },
    {
      id: 34,
      type: 'PrimitiveNode',
      pos: [432, 480],
      size: {
        '0': 210,
        '1': 82
      },
      flags: {},
      order: 2,
      mode: 0,
      outputs: [
        {
          name: 'INT',
          type: 'INT',
          links: [112, 115],
          slot_index: 0,
          widget: {
            name: 'width'
          }
        }
      ],
      title: 'width',
      properties: {
        'Run widget replace on values': false
      },
      widgets_values: [1024, 'fixed'],
      color: '#323',
      bgcolor: '#535'
    },
    {
      id: 35,
      type: 'PrimitiveNode',
      pos: [672, 480],
      size: {
        '0': 210,
        '1': 82
      },
      flags: {},
      order: 3,
      mode: 0,
      outputs: [
        {
          name: 'INT',
          type: 'INT',
          links: [113, 114],
          widget: {
            name: 'height'
          },
          slot_index: 0
        }
      ],
      title: 'height',
      properties: {
        'Run widget replace on values': false
      },
      widgets_values: [1024, 'fixed'],
      color: '#323',
      bgcolor: '#535'
    },
    {
      id: 9,
      type: 'SaveImage',
      pos: [1155, 196],
      size: {
        '0': 985.3012084960938,
        '1': 1060.3828125
      },
      flags: {},
      order: 17,
      mode: 0,
      inputs: [
        {
          name: 'images',
          type: 'IMAGE',
          link: 9
        }
      ],
      properties: {},
      widgets_values: ['ComfyUI']
    },
    {
      id: 37,
      type: 'Note',
      pos: [480, 1344],
      size: {
        '0': 314.99755859375,
        '1': 117.98363494873047
      },
      flags: {},
      order: 4,
      mode: 0,
      properties: {
        text: ''
      },
      widgets_values: [
        "The reference sampling implementation auto adjusts the shift value based on the resolution, if you don't want this you can just bypass (CTRL-B) this ModelSamplingFlux node.\n"
      ],
      color: '#432',
      bgcolor: '#653'
    },
    {
      id: 10,
      type: 'VAELoader',
      pos: [48, 432],
      size: {
        '0': 311.81634521484375,
        '1': 60.429901123046875
      },
      flags: {},
      order: 5,
      mode: 0,
      outputs: [
        {
          name: 'VAE',
          type: 'VAE',
          links: [12],
          shape: 3,
          slot_index: 0
        }
      ],
      properties: {
        'Node name for S&R': 'VAELoader'
      },
      widgets_values: ['ae.safetensors']
    },
    {
      id: 28,
      type: 'Note',
      pos: [48, 576],
      size: {
        '0': 336,
        '1': 288
      },
      flags: {},
      order: 6,
      mode: 0,
      properties: {
        text: ''
      },
      widgets_values: [
        'If you get an error in any of the nodes above make sure the files are in the correct directories.\n\nSee the top of the examples page for the links : https://comfyanonymous.github.io/ComfyUI_examples/flux/\n\nflux1-dev.safetensors goes in: ComfyUI/models/unet/\n\nt5xxl_fp16.safetensors and clip_l.safetensors go in: ComfyUI/models/clip/\n\nae.safetensors goes in: ComfyUI/models/vae/\n\n\nTip: You can set the weight_dtype above to one of the fp8 types if you have memory issues.'
      ],
      color: '#432',
      bgcolor: '#653'
    },
    {
      id: 11,
      type: 'DualCLIPLoader',
      pos: [48, 288],
      size: {
        '0': 315,
        '1': 106
      },
      flags: {},
      order: 7,
      mode: 0,
      outputs: [
        {
          name: 'CLIP',
          type: 'CLIP',
          links: [10],
          shape: 3,
          slot_index: 0
        }
      ],
      properties: {
        'Node name for S&R': 'DualCLIPLoader'
      },
      widgets_values: ['t5xxl_fp16.safetensors', 'clip_l.safetensors', 'flux']
    },
    {
      id: 12,
      type: 'UNETLoader',
      pos: [48, 144],
      size: {
        '0': 315,
        '1': 82
      },
      flags: {},
      order: 8,
      mode: 0,
      outputs: [
        {
          name: 'MODEL',
          type: 'MODEL',
          links: [56],
          shape: 3,
          slot_index: 0
        }
      ],
      properties: {
        'Node name for S&R': 'UNETLoader'
      },
      widgets_values: ['flux1-dev.safetensors', 'default'],
      color: '#223',
      bgcolor: '#335'
    }
  ],
  links: [
    [9, 8, 0, 9, 0, 'IMAGE'],
    [10, 11, 0, 6, 0, 'CLIP'],
    [12, 10, 0, 8, 1, 'VAE'],
    [19, 16, 0, 13, 2, 'SAMPLER'],
    [20, 17, 0, 13, 3, 'SIGMAS'],
    [24, 13, 0, 8, 0, 'LATENT'],
    [30, 22, 0, 13, 1, 'GUIDER'],
    [37, 25, 0, 13, 0, 'NOISE'],
    [41, 6, 0, 26, 0, 'CONDITIONING'],
    [42, 26, 0, 22, 1, 'CONDITIONING'],
    [54, 30, 0, 22, 0, 'MODEL'],
    [55, 30, 0, 17, 0, 'MODEL'],
    [56, 12, 0, 30, 0, 'MODEL'],
    [112, 34, 0, 27, 0, 'INT'],
    [113, 35, 0, 27, 1, 'INT'],
    [114, 35, 0, 30, 2, 'INT'],
    [115, 34, 0, 30, 1, 'INT'],
    [116, 27, 0, 13, 4, 'LATENT']
  ],
  groups: [],
  config: {},
  extra: {
    ds: {
      scale: 1.1,
      offset: [33.06763941350344, -12.256165227618597]
    },
    groupNodes: {
      EmptyLatentImage: {
        nodes: [
          {
            type: 'PrimitiveNode',
            pos: [432, 480],
            size: {
              '0': 210,
              '1': 82
            },
            flags: {},
            order: 6,
            mode: 0,
            outputs: [
              {
                name: 'INT',
                type: 'INT',
                links: [],
                widget: {
                  name: 'height'
                },
                slot_index: 0
              }
            ],
            title: 'height',
            properties: {
              'Run widget replace on values': false
            },
            color: '#323',
            bgcolor: '#535',
            index: 0
          },
          {
            type: 'PrimitiveNode',
            pos: [672, 480],
            size: {
              '0': 210,
              '1': 82
            },
            flags: {},
            order: 7,
            mode: 0,
            outputs: [
              {
                name: 'INT',
                type: 'INT',
                links: [],
                slot_index: 0,
                widget: {
                  name: 'width'
                }
              }
            ],
            title: 'width',
            properties: {
              'Run widget replace on values': false
            },
            color: '#323',
            bgcolor: '#535',
            index: 1
          },
          {
            type: 'EmptySD3LatentImage',
            pos: [480, 624],
            size: {
              '0': 315,
              '1': 106
            },
            flags: {},
            order: 10,
            mode: 0,
            inputs: [
              {
                name: 'width',
                type: 'INT',
                link: null,
                widget: {
                  name: 'width'
                }
              },
              {
                name: 'height',
                type: 'INT',
                link: null,
                widget: {
                  name: 'height'
                }
              }
            ],
            outputs: [
              {
                name: 'LATENT',
                type: 'LATENT',
                links: [],
                shape: 3,
                slot_index: 0
              }
            ],
            properties: {
              'Node name for S&R': 'EmptySD3LatentImage'
            },
            widgets_values: [1024, 1024, 1],
            index: 2
          }
        ],
        links: [
          [1, 0, 2, 0, 34, 'INT'],
          [0, 0, 2, 1, 35, 'INT']
        ],
        external: [
          [0, 0, 'INT'],
          [1, 0, 'INT'],
          [2, 0, 'LATENT']
        ],
        config: {
          '0': {
            output: {
              '0': {
                name: 'height'
              }
            },
            input: {
              value: {
                visible: true
              }
            }
          },
          '1': {
            output: {
              '0': {
                name: 'width'
              }
            },
            input: {
              value: {
                visible: true
              }
            }
          },
          '2': {
            input: {
              width: {
                visible: false
              },
              height: {
                visible: false
              }
            }
          }
        }
      }
    }
  },
  version: 0.4
}

export const LoRADemo: any = {
  "last_node_id": 18,
  "last_link_id": 34,
  "nodes": [
    {
      "id": 8,
      "type": "VAEDecode",
      "pos": {
        "0": 1360,
        "1": 620
      },
      "size": {
        "0": 210,
        "1": 46
      },
      "flags": {},
      "order": 9,
      "mode": 0,
      "inputs": [
        {
          "name": "samples",
          "type": "LATENT",
          "link": 7
        },
        {
          "name": "vae",
          "type": "VAE",
          "link": 8
        }
      ],
      "outputs": [
        {
          "name": "IMAGE",
          "type": "IMAGE",
          "links": [
            9
          ],
          "slot_index": 0
        }
      ],
      "properties": {
        "Node name for S&R": "VAEDecode"
      },
      "widgets_values": []
    },
    {
      "id": 13,
      "type": "SaveImage",
      "pos": {
        "0": 1593.611572265625,
        "1": 11.30574893951416
      },
      "size": {
        "0": 417.83740234375,
        "1": 489.8079833984375
      },
      "flags": {},
      "order": 12,
      "mode": 0,
      "inputs": [
        {
          "name": "images",
          "type": "IMAGE",
          "link": 16
        }
      ],
      "outputs": [],
      "properties": {},
      "widgets_values": [
        "ComfyUI"
      ]
    },
    {
      "id": 9,
      "type": "SaveImage",
      "pos": {
        "0": 1590,
        "1": 620
      },
      "size": {
        "0": 417.83740234375,
        "1": 489.8079833984375
      },
      "flags": {},
      "order": 11,
      "mode": 0,
      "inputs": [
        {
          "name": "images",
          "type": "IMAGE",
          "link": 9
        }
      ],
      "outputs": [],
      "properties": {},
      "widgets_values": [
        "ComfyUI"
      ]
    },
    {
      "id": 12,
      "type": "VAEDecode",
      "pos": {
        "0": 1358.611572265625,
        "1": 12.30574893951416
      },
      "size": {
        "0": 210,
        "1": 46
      },
      "flags": {},
      "order": 10,
      "mode": 0,
      "inputs": [
        {
          "name": "samples",
          "type": "LATENT",
          "link": 15
        },
        {
          "name": "vae",
          "type": "VAE",
          "link": 21
        }
      ],
      "outputs": [
        {
          "name": "IMAGE",
          "type": "IMAGE",
          "links": [
            16
          ],
          "slot_index": 0
        }
      ],
      "properties": {
        "Node name for S&R": "VAEDecode"
      },
      "widgets_values": []
    },
    {
      "id": 11,
      "type": "KSampler",
      "pos": {
        "0": 1005.611572265625,
        "1": 13.30574893951416
      },
      "size": {
        "0": 315,
        "1": 262
      },
      "flags": {},
      "order": 8,
      "mode": 0,
      "inputs": [
        {
          "name": "model",
          "type": "MODEL",
          "link": 17
        },
        {
          "name": "positive",
          "type": "CONDITIONING",
          "link": 33
        },
        {
          "name": "negative",
          "type": "CONDITIONING",
          "link": 34
        },
        {
          "name": "latent_image",
          "type": "LATENT",
          "link": 20
        }
      ],
      "outputs": [
        {
          "name": "LATENT",
          "type": "LATENT",
          "links": [
            15
          ],
          "slot_index": 0
        }
      ],
      "properties": {
        "Node name for S&R": "KSampler"
      },
      "widgets_values": [
        3692698692,
        "fixed",
        35,
        6,
        "euler_ancestral",
        "normal",
        1
      ]
    },
    {
      "id": 3,
      "type": "KSampler",
      "pos": {
        "0": 1010,
        "1": 620
      },
      "size": {
        "0": 315,
        "1": 262
      },
      "flags": {},
      "order": 7,
      "mode": 0,
      "inputs": [
        {
          "name": "model",
          "type": "MODEL",
          "link": 12
        },
        {
          "name": "positive",
          "type": "CONDITIONING",
          "link": 26
        },
        {
          "name": "negative",
          "type": "CONDITIONING",
          "link": 27
        },
        {
          "name": "latent_image",
          "type": "LATENT",
          "link": 2
        }
      ],
      "outputs": [
        {
          "name": "LATENT",
          "type": "LATENT",
          "links": [
            7
          ],
          "slot_index": 0
        }
      ],
      "properties": {
        "Node name for S&R": "KSampler"
      },
      "widgets_values": [
        3692698692,
        "fixed",
        35,
        6,
        "euler_ancestral",
        "normal",
        1
      ]
    },
    {
      "id": 5,
      "type": "EmptyLatentImage",
      "pos": {
        "0": 360,
        "1": 900
      },
      "size": {
        "0": 407.832763671875,
        "1": 106
      },
      "flags": {},
      "order": 0,
      "mode": 0,
      "inputs": [],
      "outputs": [
        {
          "name": "LATENT",
          "type": "LATENT",
          "links": [
            2,
            20
          ],
          "slot_index": 0
        }
      ],
      "properties": {
        "Node name for S&R": "EmptyLatentImage"
      },
      "widgets_values": [
        512,
        768,
        1
      ]
    },
    {
      "id": 18,
      "type": "String-🔬",
      "pos": {
        "0": 360,
        "1": 660
      },
      "size": {
        "0": 403.86578369140625,
        "1": 78.19261932373047
      },
      "flags": {},
      "order": 1,
      "mode": 0,
      "inputs": [],
      "outputs": [
        {
          "name": "STRING",
          "type": "STRING",
          "links": [
            32
          ],
          "slot_index": 0,
          "shape": 3
        }
      ],
      "title": "Negative Prompt",
      "properties": {
        "Node name for S&R": "String-🔬"
      },
      "widgets_values": [
        ""
      ]
    },
    {
      "id": 17,
      "type": "String-🔬",
      "pos": {
        "0": 360,
        "1": 370
      },
      "size": {
        "0": 404.5269775390625,
        "1": 129.14297485351562
      },
      "flags": {},
      "order": 2,
      "mode": 0,
      "inputs": [],
      "outputs": [
        {
          "name": "STRING",
          "type": "STRING",
          "links": [
            30
          ],
          "slot_index": 0,
          "shape": 3
        }
      ],
      "title": "Positive Prompt",
      "properties": {
        "Node name for S&R": "Positive Prompt"
      },
      "widgets_values": [
        "backyard launch of a baroque-styled rocket ship nighttime behind the garage rural home"
      ]
    },
    {
      "id": 14,
      "type": "CLIPTextEncode",
      "pos": {
        "0": 360,
        "1": 550
      },
      "size": {
        "0": 405.1881103515625,
        "1": 54
      },
      "flags": {},
      "order": 5,
      "mode": 0,
      "inputs": [
        {
          "name": "clip",
          "type": "CLIP",
          "link": 22
        },
        {
          "name": "text",
          "type": "STRING",
          "link": 30,
          "slot_index": 1,
          "widget": {
            "name": "text"
          }
        }
      ],
      "outputs": [
        {
          "name": "CONDITIONING",
          "type": "CONDITIONING",
          "links": [
            26,
            33
          ],
          "slot_index": 0
        }
      ],
      "properties": {
        "Node name for S&R": "CLIPTextEncode"
      },
      "widgets_values": [
        "beautiful lady, (freckles), big smile, brown hazel eyes, Ponytail, dark makeup, soft light, head and shoulders portrait, cover, (epic oil painting:1.3)"
      ]
    },
    {
      "id": 15,
      "type": "CLIPTextEncode",
      "pos": {
        "0": 360,
        "1": 790
      },
      "size": {
        "0": 407.832763671875,
        "1": 54
      },
      "flags": {},
      "order": 6,
      "mode": 0,
      "inputs": [
        {
          "name": "clip",
          "type": "CLIP",
          "link": 23
        },
        {
          "name": "text",
          "type": "STRING",
          "link": 32,
          "widget": {
            "name": "text"
          }
        }
      ],
      "outputs": [
        {
          "name": "CONDITIONING",
          "type": "CONDITIONING",
          "links": [
            27,
            34
          ],
          "slot_index": 0
        }
      ],
      "properties": {
        "Node name for S&R": "CLIPTextEncode"
      },
      "widgets_values": [
        "bad eyes, plastic, deformed, watermark"
      ]
    },
    {
      "id": 4,
      "type": "CheckpointLoaderSimple",
      "pos": {
        "0": 360,
        "1": 40
      },
      "size": {
        "0": 404.5269775390625,
        "1": 98
      },
      "flags": {},
      "order": 3,
      "mode": 0,
      "inputs": [],
      "outputs": [
        {
          "name": "MODEL",
          "type": "MODEL",
          "links": [
            11,
            17
          ],
          "slot_index": 0
        },
        {
          "name": "CLIP",
          "type": "CLIP",
          "links": [
            10
          ],
          "slot_index": 1
        },
        {
          "name": "VAE",
          "type": "VAE",
          "links": [
            8,
            21
          ],
          "slot_index": 2
        }
      ],
      "properties": {
        "Node name for S&R": "CheckpointLoaderSimple"
      },
      "widgets_values": [
        "Juggernaut-XL.safetensors"
      ]
    },
    {
      "id": 10,
      "type": "LoraLoader",
      "pos": {
        "0": 360,
        "1": 190
      },
      "size": {
        "0": 405.1881103515625,
        "1": 126
      },
      "flags": {},
      "order": 4,
      "mode": 0,
      "inputs": [
        {
          "name": "model",
          "type": "MODEL",
          "link": 11
        },
        {
          "name": "clip",
          "type": "CLIP",
          "link": 10
        }
      ],
      "outputs": [
        {
          "name": "MODEL",
          "type": "MODEL",
          "links": [
            12
          ],
          "slot_index": 0,
          "shape": 3,
          "tooltip": "The modified diffusion model."
        },
        {
          "name": "CLIP",
          "type": "CLIP",
          "links": [
            22,
            23
          ],
          "slot_index": 1,
          "shape": 3,
          "tooltip": "The modified CLIP model."
        }
      ],
      "properties": {
        "Node name for S&R": "LoraLoader"
      },
      "widgets_values": [
        "Set_Custom_Lora_Using_Below_Menu.safetensors",
        1,
        1
      ]
    }
  ],
  "links": [
    [
      2,
      5,
      0,
      3,
      3,
      "LATENT"
    ],
    [
      7,
      3,
      0,
      8,
      0,
      "LATENT"
    ],
    [
      8,
      4,
      2,
      8,
      1,
      "VAE"
    ],
    [
      9,
      8,
      0,
      9,
      0,
      "IMAGE"
    ],
    [
      10,
      4,
      1,
      10,
      1,
      "CLIP"
    ],
    [
      11,
      4,
      0,
      10,
      0,
      "MODEL"
    ],
    [
      12,
      10,
      0,
      3,
      0,
      "MODEL"
    ],
    [
      15,
      11,
      0,
      12,
      0,
      "LATENT"
    ],
    [
      16,
      12,
      0,
      13,
      0,
      "IMAGE"
    ],
    [
      17,
      4,
      0,
      11,
      0,
      "MODEL"
    ],
    [
      20,
      5,
      0,
      11,
      3,
      "LATENT"
    ],
    [
      21,
      4,
      2,
      12,
      1,
      "VAE"
    ],
    [
      22,
      10,
      1,
      14,
      0,
      "CLIP"
    ],
    [
      23,
      10,
      1,
      15,
      0,
      "CLIP"
    ],
    [
      26,
      14,
      0,
      3,
      1,
      "CONDITIONING"
    ],
    [
      27,
      15,
      0,
      3,
      2,
      "CONDITIONING"
    ],
    [
      30,
      17,
      0,
      14,
      1,
      "STRING"
    ],
    [
      32,
      18,
      0,
      15,
      1,
      "STRING"
    ],
    [
      33,
      14,
      0,
      11,
      1,
      "CONDITIONING"
    ],
    [
      34,
      15,
      0,
      11,
      2,
      "CONDITIONING"
    ]
  ],
  "groups": [
    {
      "title": "Generation without Lora",
      "bounding": [
        958,
        -112,
        1074,
        628
      ],
      "color": "#3f789e",
      "font_size": 24,
      "flags": {}
    },
    {
      "title": "Generation with Lora",
      "bounding": [
        961,
        544,
        1070,
        594
      ],
      "color": "#3f789e",
      "font_size": 24,
      "flags": {}
    }
  ],
  "config": {},
  "extra": {
    "ds": {
      "scale": 0.620921323059155,
      "offset": {
        "0": 10.041193008422852,
        "1": 319.14276123046875
      }
    }
  },
  "version": 0.4
}