import { app } from '@/scripts/app'
import { api } from '@/scripts/api'
import { State } from './override'

let settings = null

window.addEventListener('message', async function (event) {
  try {
    var info = JSON.parse(event.data)

    switch (info.type) {
      case 'load':
        var workflowData = info.data
        var timer = window.setInterval(function () {
          if (State.inited) {
            if (!State.loaded) app.loadGraphData(workflowData)
            window.clearInterval(timer)
          }
        }, 1000)
        break
      case 'save':
        top.postMessage(
          JSON.stringify({
            type: 'save',
            data: await app.graphToPrompt()
          }),
          '*'
        )
        break
      case 'settings':
        settings = info.data

        const p = await app.graphToPrompt()
        app.loadGraphData(p.workflow)
        break
    }
  } catch (error) {
    console.log(error)
  }
})

var events = [
  'status',
  'progress',
  'executing',
  'executed',
  'execution_start',
  'execution_success',
  'execution_error',
  'execution_cached'
]

for (var event of events) {
  ;(function (event) {
    api.addEventListener(event, () => {
      top.postMessage(
        JSON.stringify({
          type: 'track',
          data: event
        }),
        '*'
      )
    })
  })(event)
}

export function injectCustomModels(inputs) {
  if (settings == null) return inputs

  inputs = JSON.parse(JSON.stringify(inputs))

  var map = {
    ckpt_name: 'checkpoints',
    vae_name: 'vae',
    control_net_name: 'controlnet',
    unet_name: 'unet',
    clip_name: 'clip',
    clip_name1: 'clip',
    clip_name2: 'clip',
    model_name: 'upscale_models',
    lora_name: 'loras'
  }

  for (const inputName in inputs) {
    var inputData = inputs[inputName]
    var type = inputData[0]

    if (Array.isArray(type)) {
      if (map[inputName]) {
        var set = new Set(inputData[0])

        for (var item of settings) {
          if (item.type == map[inputName]) {
            set.add(item.filename)
          }
        }

        inputData[0] = Array.from(set)
      }
    }
  }

  return inputs
}

export default {
  init: () => {}
}
